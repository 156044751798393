const DetailArtikelContoh = {
  async render() {
    return `
    <article>
    <section class="detail">
      <div class="lokasi">
            <p> <a href="#/beranda"> Beranda</a> >> <a href="#/detail"> Detail Artikel </a></p>
          </div>

      <div class="detailImg">
        <picture>
          <source srcset="images/image_9-small.jpg 480w, images/image_9-large.jpg 800w" sizes="(max-width: 600px) 480px, 800px" type="image/jpg">
          <img src="images/image_9.png" alt="" class = "lazyload" />
        </picture>
      </div>
      <div class="detailAboutDescription">
        <p>
          Peran Ekonomi Hijau, Konsumsi dan Produksi Berkelanjutan, serta Efisiensi Sumber Daya dalam Pembangunan Berkelanjutan: Konsumsi dan Produksi Berkelanjutan bertujuan untuk meningkatkan proses produksi dan pola konsumsi agar mengurangi penggunaan sumber daya, produksi limbah, dan emisi sepanjang siklus hidup produk dan proses. Sementara itu, Efisiensi Sumber Daya merujuk pada cara penggunaan sumber daya untuk memberikan nilai kepada masyarakat dengan tujuan mengurangi jumlah sumber daya yang dibutuhkan, serta emisi dan limbah yang dihasilkan, per unit produk atau layanan. Ekonomi Hijau menawarkan pendekatan makro-ekonomi terhadap pertumbuhan ekonomi yang berkelanjutan dengan fokus utama pada investasi, penciptaan lapangan kerja, dan pengembangan keterampilan.
        </p>
        <br>
        <p>
        Dengan mengadopsi ekonomi hijau, negara-negara di Asia dan Pasifik diharapkan dapat mencapai pembangunan yang lebih inklusif dan berkelanjutan, di mana pertumbuhan ekonomi sejalan dengan pelestarian lingkungan dan peningkatan kesejahteraan sosial.
        </p>
      </div>
    </section>
  </article>
    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default DetailArtikelContoh;

const DetailGalleryContoh = {
  async render() {
    return `
    <article>
    <section class="detail">
      <div class="lokasi">
            <p> <a href="#/beranda"> Beranda</a> >> <a href="#/detail"> Detail Artikel </a></p>
          </div>

      <div class="detailImg">
        <picture>
          <source srcset="images/csa-small.jpg 480w, images/csa-large.jpg 800w" sizes="(max-width: 600px) 480px, 800px" type="image/jpg">
          <img src="images/csa.png" alt="" class = "lazyload" />
        </picture>
      </div>
      <div class="detailAboutDescription">
        <p>
        kolaborasi dengan Unit kegiatan Mahasiswa CSA (Creative Student Association) 
        dengan mengadakan workshop seminar dengan mengundang beberapa narasumber dengan 
        keahlian dibidang greeneconomy
        </p>
      </div>
    </section>
  </article>
    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default DetailGalleryContoh;
